import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { ManipulateType } from 'dayjs';
import { useParams, useSearchParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import {
  filterOutListingsAtom,
  hasSearchResultsAtom,
  initialPageLoadAtom,
  searchParamsHistoryAtom,
  searchResultsAtom,
  selectedSearchEventsAtom,
  selectedTenantListingIdAtom,
  showEventPerformanceAtom,
  updateEventListingsAtom,
} from '../../data/atoms';
import { BarkerCoreEnumsPricerStatus, BarkerCoreModelsInventoryEvent, GetApiInventoryParams, getGetApiInventoryQueryKey, useGetApiInventory } from '../../api';
import { SortOrder } from '../../components/Select/OrderSelect';
import { useGlobalState } from '../../data/GlobalState';
import { useRuleState } from '../../data/RuleState';
import yasml from '@thirtytech/yasml';
import { useDebouncedState, useDidUpdate } from '@mantine/hooks';
import { useFlag } from '@unleash/proxy-client-react';
import { auth } from '../../data/atoms.auth';
import { MobileDateOptions } from './Search.types';
import { useSelectedTenantTags } from './SelectedTenantsTags.hooks';
import { InventorySearchParamsWithDates, PricerView } from '../../types';
import { useDelayedValue } from '../../utils/use-delayed-value';
import { useListingState } from '../../data/ListingState';
import { splitSearchRange } from '../../utils/date-utils';
import { TenantIdEventId } from '../../models/tenantIdListingId';

export type SearchParams = {
  query: string;
  fromDate: string | null;
  toDate: string | null;
  pricerStatuses: BarkerCoreEnumsPricerStatus[] | undefined;
  purchaseDateFrom: string | undefined;
  purchaseDateTo: string | undefined;
  daysSinceRepriced: number | undefined;
  daysSinceViewed: number | undefined;
  listingId: string | undefined;
  eventId: string | undefined;
  tags: string[] | undefined;
  antiTags: string[] | undefined;
};

export type SearchParamsWithDates = {
  fromDate: Date | undefined;
  toDate: Date | undefined;
  purchaseDateFrom: Date | undefined;
  purchaseDateTo: Date | undefined;
} & Omit<SearchParams, 'fromDate' | 'toDate' | 'purchaseDateFrom' | 'purchaseDateTo'>;

export const onRefetchInventory = new Event('onRefetchInventory');
export const onRefetchInventoryCompleted = new Event('onRefetchInventoryCompleted');

const SearchResultsState = () => {
  const { pricerView } = useGlobalState('pricerView');
  const [selectedEvents, setSelectedEvents] = useAtom(selectedSearchEventsAtom);
  const setHasSearchResults = useSetAtom(hasSearchResultsAtom);
  const [searchResults, setSearchResults] = useAtom(searchResultsAtom);
  const [{ tenantId, listingId: selectedListingId }, setSelectedListingId] = useAtom(selectedTenantListingIdAtom);
  const updateEventListings = useSetAtom(updateEventListingsAtom);
  const sortOptions = useMemo(() => ['Date', 'Name', 'Venue', 'City', 'State', 'Last Viewed', 'Tickets Remaining'] as const, []);
  const [sort, setSort] = useState<(typeof sortOptions)[number]>('Date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [searchParams, setSearchParams] = useSearchParams();
  const setIsInitialPageLoad = useSetAtom(initialPageLoadAtom);
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const setEventPerformance = useSetAtom(showEventPerformanceAtom);
  const { tenants, setIsAnimationEnabled, isMobile } = useGlobalState('tenants', 'setIsAnimationEnabled', 'isMobile');
  const { lastClickedActiveEventId, setLastClickedActiveEventId } = useListingState('lastClickedActiveEventId', 'setLastClickedActiveEventId');
  const fixedEndOfDate = 'T23:59:59.000';
  const fixedStartDate = 'T00:00:00.000';
  const ignoreListing = useSetAtom(filterOutListingsAtom);
  const principalSettings = useAtomValue(auth.principalAtom)?.settings;
  const selectedTenantsTagsResults = useSelectedTenantTags();
  const hideCostFlag = useFlag('hide-cost');
  const { eventId: routeEventId } = useParams();

  const formatSearchRangeDefault: [number, ManipulateType] | undefined = useMemo(
    () => splitSearchRange(principalSettings?.pricerSettings?.searchRange),
    [principalSettings?.pricerSettings?.searchRange],
  );

  const _formatSearchParams = useCallback(
    (params: URLSearchParams | null) =>
      ({
        query: params?.get('query') ?? '',
        fromDate: params?.get('fromDate') ? dayjs(params?.get('fromDate')).toDate() : dayjs().startOf('day').toDate(),
        toDate: params?.get('toDate')
          ? dayjs(params?.get('toDate')).endOf('day').toDate()
          : params?.size === 0 || params?.get('tenantId')
            ? isMobile
              ? !params?.get('tenantId')
                ? dayjs().endOf('day').toDate()
                : undefined
              : formatSearchRangeDefault
                ? dayjs()
                    .add(...formatSearchRangeDefault)
                    .endOf('day')
                    .toDate()
                : undefined
            : isMobile
              ? dayjs().endOf('day').toDate()
              : undefined,
        pricerStatuses: params?.get('pricerStatuses') ? params?.getAll('pricerStatuses').map((x) => x as BarkerCoreEnumsPricerStatus) : [],
        purchaseDateFrom: params?.get('purchaseDateFrom') ? dayjs(params?.get('purchaseDateFrom')).toDate() : undefined,
        purchaseDateTo: params?.get('purchaseDateTo') ? dayjs(params?.get('purchaseDateTo')).endOf('day').toDate() : undefined,
        daysSinceRepriced: params?.get('daysSinceRepriced') ? parseInt(params?.get('daysSinceRepriced') ?? '0') : undefined,
        daysSinceViewed: params?.get('daysSinceViewed') ? parseInt(params?.get('daysSinceViewed') ?? '0') : undefined,
        listingId: params?.get('listingId') ?? '',
        eventId: params?.get('eventId') ?? routeEventId ?? '',
        tags: params?.get('tags') ? params?.getAll('tags') : [],
        antiTags: params?.get('antiTags') ? params?.getAll('antiTags') : [],
        daysSinceLastSale: params?.get('daysSinceLastSale') ? parseInt(params?.get('daysSinceLastSale') ?? '0') : undefined,
        onlyNotBroadcasting: params?.get('onlyNotBroadcasting') ? params?.get('onlyNotBroadcasting') === 'true' : undefined,
        showEvents: params?.get('showEvents') ?? 'open',
      }) as InventorySearchParamsWithDates,
    [formatSearchRangeDefault, isMobile, routeEventId],
  );

  const { validateDirtyRuleState, resetForm: resetNowPricingForm, triggerSaveRule } = useRuleState('validateDirtyRuleState', 'resetForm', 'triggerSaveRule');
  const [searchHistory, setSearchHistory] = useAtom(searchParamsHistoryAtom);
  const [state, setStateDebounced] = useDebouncedState(_formatSearchParams(searchParams), 250);
  const setState = useCallback(
    (_state: InventorySearchParamsWithDates) => {
      setStateDebounced(_state);
    },
    [setStateDebounced],
  );

  const form = useForm<InventorySearchParamsWithDates>({
    // mode: 'uncontrolled',
    validateInputOnChange: true,
    initialValues: {
      ...state,
    },
    validate: {
      // toDate: (value) => (!value ? 'To date is required' : undefined),
      // fromDate: (value) => (!value ? 'From date is required' : undefined),
    },
  });

  const { resetDirty, isDirty, setValues } = form;

  useDidUpdate(() => {
    if (!isMobile && !searchHistory) {
      setState(_formatSearchParams(searchParams));
      form.setValues(_formatSearchParams(searchParams));
    }
  }, [searchParams]);

  const selectedTenantsTags = useMemo(() => {
    if (form.values.tags?.some((t) => !selectedTenantsTagsResults.includes(t.toUpperCase()))) {
      form.setValues({ tags: form.values.tags.filter((t) => selectedTenantsTagsResults.includes(t.toUpperCase())) });
    }
    return selectedTenantsTagsResults;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenantsTagsResults]);

  const apiQueryObj: GetApiInventoryParams = useMemo(
    () => ({
      fromDate: state.fromDate ? ((dayjs(state.fromDate).format('YYYY-MM-DD') + fixedStartDate) as unknown as Date) : (dayjs().format('YYYY-MM-DD') as unknown as Date),
      toDate: state.toDate ? ((dayjs(state.toDate).endOf('day').format('YYYY-MM-DD') + fixedEndOfDate) as unknown as Date) : ('2099-12-31' as unknown as Date),
      query: state.query,
      pricerStatuses: state.pricerStatuses === undefined ? undefined : state.pricerStatuses,
      purchaseDateFrom: state.purchaseDateFrom === undefined ? undefined : dayjs(state.purchaseDateFrom).toDate(),
      purchaseDateTo: state.purchaseDateTo === undefined ? undefined : dayjs(state.purchaseDateTo).endOf('day').toDate(),
      daysSinceRepriced: state.daysSinceRepriced,
      daysSinceViewed: state.daysSinceViewed,
      listingIds: state.listingId ? [state.listingId] : [],
      eventIds: state.eventId ? [state.eventId] : [],
      tags: state.tags,
      antiTags: state.antiTags,
      daysSinceLastSale: state.daysSinceLastSale,
      onlyNotBroadcasting: state.onlyNotBroadcasting,
      includeSoldOutEvents: state.showEvents === 'mine' ? true : undefined,
      includeAllEvents: state.showEvents === 'all' ? true : undefined,
    }),
    [state],
  );

  useEffect(() => {
    setSearchHistory(apiQueryObj);
  }, [apiQueryObj, setSearchHistory]);

  const {
    refetch: refetchInventory,
    data: inventoryData,
    isFetching,
  } = useGetApiInventory(apiQueryObj, {
    query: {
      enabled: !!apiToken && !!tenants && !!principalSettings,
      select(data) {
        if (!hideCostFlag) {
          return data.data;
        }
        return {
          events: data.data.events.map((event) => ({
            ...event,
            openCost: 0,
          })),
          listings: data.data.listings.map((listing) => ({
            ...listing,
            cost: 0,
          })),
        };
      },
    },
  });

  useEffect(() => {
    if (!isFetching) {
      document.dispatchEvent(onRefetchInventoryCompleted);
    }
  }, [isFetching]);

  const refetchInventoryEvent = useCallback(() => {
    refetchInventory();
  }, [refetchInventory]);

  useEffect(() => {
    document.addEventListener(onRefetchInventory.type, refetchInventoryEvent);
    return () => {
      document.removeEventListener(onRefetchInventory.type, refetchInventoryEvent);
    };
  }, [refetchInventoryEvent]);

  const search = useCallback(
    async (values: InventorySearchParamsWithDates, valuesAreDirty?: boolean) => {
      if ((await validateDirtyRuleState()) && form.isValid()) {
        await triggerSaveRule();
        setState({ ...values }); // Form values are already set but need to tell the related components something changed
        setLastClickedActiveEventId(null);
        ignoreListing([]);
        setIsAnimationEnabled(false);
        setEventPerformance(false);

        const newParams: any = {};

        if (values.fromDate) {
          newParams.fromDate = dayjs(values.fromDate).format('YYYY-MM-DD');
        }

        if (values.toDate) {
          newParams.toDate = dayjs(values.toDate).format('YYYY-MM-DD');
        }

        if (values.query !== '') {
          newParams.query = values.query;
        }

        if ((values.pricerStatuses?.length ?? 0) > 0) {
          newParams.pricerStatuses = values.pricerStatuses;
        }

        if (values.purchaseDateFrom) {
          newParams.purchaseDateFrom = dayjs(values.purchaseDateFrom).format('YYYY-MM-DD');
        }

        if (values.purchaseDateTo) {
          newParams.purchaseDateTo = dayjs(values.purchaseDateTo).format('YYYY-MM-DD');
        }

        if (values.daysSinceRepriced !== undefined) {
          newParams.daysSinceRepriced = values.daysSinceRepriced;
        }

        if (values.daysSinceViewed !== undefined) {
          newParams.daysSinceViewed = values.daysSinceViewed;
        }

        if (values.daysSinceLastSale !== undefined) {
          newParams.daysSinceLastSale = values.daysSinceLastSale;
        }

        if (values.onlyNotBroadcasting === true) {
          newParams.onlyNotBroadcasting = values.onlyNotBroadcasting;
        }

        if (values.listingId) {
          newParams.listingId = values.listingId;
        }

        if (values.eventId) {
          newParams.eventId = values.eventId;
        }

        const stateTagsNotInSelectedTenantsTags = values.tags?.some((t) => !selectedTenantsTags.includes(t));

        if ((values.tags?.length ?? 0) > 0 || stateTagsNotInSelectedTenantsTags) {
          const onlySelectedTenantsTags = values.tags.filter((t) => selectedTenantsTags.includes(t));
          newParams.tags = values.tags.filter((vt) => onlySelectedTenantsTags.includes(vt));
        }

        if ((values.antiTags?.length ?? 0) > 0) {
          newParams.antiTags = values.antiTags;
        }

        if (values.showEvents !== 'open') {
          newParams.showEvents = values.showEvents;
        }

        if (!isDirty() && !valuesAreDirty) {
          await refetchInventory();
        } else {
          setSearchParams(() => newParams);
          resetDirty();
        }
      }
    },
    [
      validateDirtyRuleState,
      form,
      triggerSaveRule,
      setState,
      setLastClickedActiveEventId,
      ignoreListing,
      setIsAnimationEnabled,
      setEventPerformance,
      isDirty,
      selectedTenantsTags,
      refetchInventory,
      setSearchParams,
      resetDirty,
    ],
  );

  const reset = useCallback(() => {
    setIsAnimationEnabled(false);
    setSelectedListingId(null);
    setSearchParams();
    setState(_formatSearchParams(null));
    setValues(_formatSearchParams(null));
    setSelectedEvents([]);
    refetchInventory();
    setEventPerformance(false);
  }, [setIsAnimationEnabled, setSelectedListingId, setSearchParams, setState, _formatSearchParams, setValues, setSelectedEvents, refetchInventory, setEventPerformance]);

  useDidUpdate(() => {
    if (pricerView !== PricerView.Split) {
      setSelectedEvents(inventoryData?.events || []);
    }
    setSearchResults(structuredClone(inventoryData) || { events: [], listings: [] });
    if (inventoryData?.listings.length === 0) {
      setIsInitialPageLoad(false);
    }
    resetNowPricingForm();
  }, [inventoryData]);

  useDidUpdate(() => {
    if (!inventoryData?.listings.some((x) => x.tenantId === tenantId && x.listingId === selectedListingId)) {
      setSelectedListingId(null);
    }
  }, [inventoryData]);

  const events = useMemo(() => searchResults?.events || [], [searchResults?.events]);
  const sortedData = useMemo(
    () =>
      [...events!]
        .sort((a, b) => a.tenantId.localeCompare(b.tenantId)) // These extra two sorts are so that the results are consistent and don't jump around
        .sort((a, b) => a.eventId.localeCompare(b.eventId))
        .sort((a, b) => {
          switch (sort) {
            case 'Name':
              return sortOrder === 'asc'
                ? a.name.localeCompare(b.name) || a.tenantId.localeCompare(b.tenantId)
                : b.name.localeCompare(a.name) || b.tenantId.localeCompare(a.tenantId);
            case 'Venue':
              return sortOrder === 'asc'
                ? a.venue.name.localeCompare(b.venue.name) || a.tenantId.localeCompare(b.tenantId)
                : b.venue.name.localeCompare(a.venue.name) || b.tenantId.localeCompare(a.tenantId);
            case 'City':
              return sortOrder === 'asc'
                ? (a.venue.city && b.venue.city && a.venue.city.localeCompare(b.venue.city)) || a.tenantId.localeCompare(b.tenantId) || 0
                : (b.venue.city && a.venue.city && b.venue.city.localeCompare(a.venue.city)) || b.tenantId.localeCompare(a.tenantId) || 0;
            case 'State':
              return sortOrder === 'asc'
                ? (a.venue.state && b.venue.state && a.venue.state.localeCompare(b.venue.state)) || a.tenantId.localeCompare(b.tenantId) || 0
                : (b.venue.state && a.venue.state && b.venue.state.localeCompare(a.venue.state)) || b.tenantId.localeCompare(a.tenantId) || 0;
            case 'Last Viewed':
              return sortOrder === 'asc'
                ? dayjs(a.viewedAt ?? new Date('2001-01-01')).isBefore(b.viewedAt ?? new Date('2001-01-01'))
                  ? 1
                  : dayjs(a.viewedAt ?? new Date('2001-01-01')).isAfter(b.viewedAt ?? new Date('2001-01-01'))
                    ? -1
                    : a.tenantId.localeCompare(b.tenantId)
                : dayjs(a.viewedAt ?? new Date('2001-01-01')).isBefore(b.viewedAt ?? new Date('2001-01-01'))
                  ? -1
                  : dayjs(a.viewedAt ?? new Date('2001-01-01')).isAfter(b.viewedAt ?? new Date('2001-01-01'))
                    ? 1
                    : b.tenantId.localeCompare(a.tenantId);
            case 'Tickets Remaining':
              return sortOrder === 'asc'
                ? a.openTickets! > b.openTickets!
                  ? 1
                  : a.openTickets! < b.openTickets!
                    ? -1
                    : a.tenantId.localeCompare(b.tenantId)
                : a.openTickets! > b.openTickets!
                  ? -1
                  : a.openTickets! < b.openTickets!
                    ? 1
                    : b.tenantId.localeCompare(a.tenantId);
            case 'Date':
              return sortOrder === 'asc'
                ? dayjs(a.localDateTime).isBefore(b.localDateTime)
                  ? -1
                  : dayjs(a.localDateTime).isAfter(b.localDateTime)
                    ? 1
                    : a.name.localeCompare(b.name) || a.tenantId.localeCompare(b.tenantId)
                : dayjs(a.localDateTime).isBefore(b.localDateTime)
                  ? 1
                  : dayjs(a.localDateTime).isAfter(b.localDateTime)
                    ? -1
                    : b.name.localeCompare(a.name) || b.tenantId.localeCompare(a.tenantId);
            default:
              return dayjs(a.localDateTime).isBefore(b.localDateTime) ? 1 : -1;
          }
        }) || [],
    [events, sort, sortOrder],
  );

  const [isLoadingDelayed] = useDelayedValue(isFetching, 10, true);

  useDidUpdate(() => {
    setHasSearchResults(!!events?.length);
  }, [events]);

  const {
    data: singleEventListings,
    isFetching: isLoadingSingleEvent,
    refetch: refetchLastClickedEvent,
  } = useGetApiInventory(
    {
      ...apiQueryObj,
      eventIds: [lastClickedActiveEventId?.eventId || ''],
    },
    {
      query: {
        queryKey: [...getGetApiInventoryQueryKey({ ...apiQueryObj, eventIds: [lastClickedActiveEventId?.eventId || ''] }), lastClickedActiveEventId?.tenantId],
        enabled: !!lastClickedActiveEventId?.hasValue(),
        select(data) {
          return data.data;
        },
      },
      axios: {
        headers: {
          'x-tenant-id': lastClickedActiveEventId?.tenantId,
        },
      },
    },
  );

  const animationFlag = useFlag('use-animations');
  useDidUpdate(() => {
    if (singleEventListings && lastClickedActiveEventId) {
      if (animationFlag) {
        setIsAnimationEnabled(true);
      }
      const { tenantId: _tenantId, eventId } = lastClickedActiveEventId;
      updateEventListings({ tenantId: _tenantId, eventId, ...singleEventListings });
    }
  }, [singleEventListings]);

  const onEventClicked = useCallback(
    async (event: BarkerCoreModelsInventoryEvent, domEvent: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsAnimationEnabled(false);
      if (await validateDirtyRuleState()) {
        const { ctrlKey, metaKey } = domEvent;
        const alreadySelected = selectedEvents.filter((o) => o.tenantId === event.tenantId && o.eventId === event.eventId).length > 0;
        const allSelected = selectedEvents.length === searchResults?.events?.length;
        if (!alreadySelected || allSelected) {
          await triggerSaveRule();
          setLastClickedActiveEventId(TenantIdEventId.create(event.tenantId, event.eventId)); // Store eventId to fetch events manually to merge into collection later
        }

        if (ctrlKey || metaKey) {
          setSelectedEvents((prev) => {
            if (prev.filter((o) => o.tenantId === event.tenantId && o.eventId === event.eventId).length > 0) {
              const result = [...prev.filter((o) => o.tenantId !== event.tenantId && o.eventId !== event.eventId)];
              if (result.length === 0) {
                return searchResults?.events || [];
              }
              return result;
            }
            return [...prev, event];
          });
        } else if (alreadySelected && selectedEvents.length === 1) {
          setSelectedEvents(searchResults?.events || []);
        } else if (alreadySelected && allSelected) {
          setSelectedEvents([event]);
        } else if (!alreadySelected) {
          setSelectedEvents([event]);
        } else if (alreadySelected && selectedEvents.length > 1) {
          setSelectedEvents((prev) => prev.filter((o) => o.eventId !== event.tenantId && o.eventId !== event.eventId));
        }
      }
    },
    [searchResults?.events, selectedEvents, setIsAnimationEnabled, setLastClickedActiveEventId, setSelectedEvents, triggerSaveRule, validateDirtyRuleState],
  );

  const mobileDateLabel: MobileDateOptions =
    !form.values.fromDate && !form.values.toDate
      ? 'All'
      : form.values.fromDate?.getTime() === dayjs().startOf('day').toDate().getTime() && form.values.toDate?.getTime() === dayjs().endOf('day').toDate().getTime()
        ? 'Today'
        : form.values.fromDate?.getTime() === dayjs().startOf('day').toDate().getTime() && form.values.toDate?.getTime() === dayjs().add(7, 'day').endOf('day').toDate().getTime()
          ? '7 Days'
          : form.values.fromDate?.getTime() === dayjs().startOf('day').toDate().getTime() &&
              form.values.toDate?.getTime() === dayjs().add(30, 'day').endOf('day').toDate().getTime()
            ? '30 Days'
            : 'Custom';

  return {
    form,
    search,
    reset,
    sortedData,
    sort,
    setSort,
    sortOptions,
    sortOrder,
    setSortOrder,
    onEventClicked,
    selectedEvents,
    isLoading: isLoadingDelayed,
    tags: selectedTenantsTags,
    isLoadingEventId: isLoadingSingleEvent ? lastClickedActiveEventId : null,
    refetchLastClickedEvent,
    mobileDateLabel,
    refetchInventory,
  };
};

export const { Provider: SearchResultStateProvider, useSelector: useSearchResults } = yasml(SearchResultsState);
