import { ColDef, ColumnState, GridReadyEvent, IRowNode } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useForm } from '@mantine/form';
import { useDidUpdate, useLocalStorage, useScrollIntoView } from '@mantine/hooks';
import yasml from '@thirtytech/yasml';
import dayjs from 'dayjs';
import { useCallback, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  BarkerCoreModelsDTIBulkPurchaseRequest,
  BarkerCoreModelsDTIGetEventsResponseItem,
  BarkerCoreModelsInventoryEvent,
  useGetDtiEvents,
  usePostDtiListingsAccountId,
} from '../../api';
import { useGlobalState } from '../../data/GlobalState';
import { FlattenObjectKeys } from '../../ts-utils';
import { delay } from '../../utils/delay';
import { formatCurrency, formatDate } from '../../utils/formatters';
import { DateFormats } from '../../utils/globals';
import { useMemoDeep } from '../../utils/use-memo-weak';
import { Order, TicketGroup } from '../../types';

export const DEFAULT_ORDER: Order = {
  section: '',
  row: '',
  seat_from: undefined,
  quantity: undefined,
  stock: 8,
  cost: undefined,
  price: 99999,
  notes: '',
  internal_notes: '',
  event_id: undefined,
  multiday: false,
  odd_even: false,
  purchaser: '',
  account_id: undefined,
  account_info: '',
  order_number: '',
  barcodes: [],
  disclosures: [],
  key: uuid(),
  location_id: undefined,
};

export type SummaryGridItem = BarkerCoreModelsDTIGetEventsResponseItem & Order;

function PurchaseState({ initialEvent, onClose }: { initialEvent?: BarkerCoreModelsInventoryEvent; onClose?: (bypass: boolean) => void }) {
  const isSelectEventsEnabled = !initialEvent;
  const initialEventConverted = useMemo<BarkerCoreModelsDTIGetEventsResponseItem | undefined>(
    () =>
      initialEvent
        ? {
            id: parseInt(initialEvent.eventId),
            event_date: formatDate(initialEvent.localDateTime, DateFormats.GridFull),
            event_date_time: formatDate(initialEvent.localDateTime, DateFormats.GridFull),
            event_name: initialEvent.name,
            venue_name: initialEvent.venue.name,
            venue_id: 0,
            isContingent: false,
            tags: [],
            event_headliner: initialEvent.performer.name,
          }
        : undefined,
    [initialEvent],
  );
  const catalogGridRef = useRef<AgGridReact<BarkerCoreModelsDTIGetEventsResponseItem>>(null);
  const summaryGridRef = useRef<AgGridReact<SummaryGridItem>>(null);
  const { dtiAccounts } = useGlobalState('dtiAccounts');
  const [orderEvents, setOrderEvents] = useState<BarkerCoreModelsDTIGetEventsResponseItem[]>(initialEventConverted ? [initialEventConverted] : []);
  const [selectedEvents, setSelectedEvents] = useState<BarkerCoreModelsDTIGetEventsResponseItem[]>(initialEventConverted ? [initialEventConverted] : []);
  const [active, setActive] = useState(0);
  const [isAccountError, setIsAccountError] = useState(false);
  const [selectedAccountId, _setSelectedAccountId] = useState<number | undefined>(dtiAccounts.length > 1 ? undefined : dtiAccounts.filter((x) => x.id !== -100)[0]?.id);
  const setSelectedAccountId = useCallback((id: number | undefined) => {
    setIsAccountError(false);
    _setSelectedAccountId(id);
  }, []);
  const nextStep = useCallback(() => {
    if (!selectedAccountId) {
      setIsAccountError(true);
    } else {
      setIsAccountError(false);
      setActive((current) => (current < 3 ? current + 1 : current));
    }
  }, [selectedAccountId]);
  const prevStep = useCallback(() => setActive((current) => (current > 0 ? current - 1 : current)), []);
  useDidUpdate(() => setSelectedAccountId(dtiAccounts.length > 1 ? undefined : dtiAccounts[0]?.id), [dtiAccounts]);
  const [activeListing, setActiveListing] = useState<{ groupIndex: number; index: number }>({ groupIndex: 0, index: 0 });
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<HTMLDivElement>({ offset: 0 });
  const form = useForm<{ groups: TicketGroup[] }>({
    initialValues: {
      groups: [
        {
          eventIds: initialEventConverted ? [initialEventConverted.id] : [],
          orders: [DEFAULT_ORDER],
          group: uuid(),
        },
      ],
    },
    validate: (values) => {
      const errors: Partial<Record<FlattenObjectKeys<typeof values>, string>> = {};
      const eventOrderHashes = new Set<string>();
      values.groups.forEach((group, groupIndex) => {
        group.orders.forEach((order, index) => {
          if (!order.section && !order.row && !order.seat_from && !order.quantity) {
            return;
          }
          if (typeof order.price !== 'number') {
            errors[`groups.${groupIndex}.orders.${index}.price`] = 'Price is required';
          }
          if (!order.section) {
            errors[`groups.${groupIndex}.orders.${index}.section`] = 'Section is required';
          }
          if (!order.row) {
            errors[`groups.${groupIndex}.orders.${index}.row`] = 'Row is required';
          }
          if (!order.seat_from) {
            errors[`groups.${groupIndex}.orders.${index}.seat_from`] = 'Seat From is required';
          }
          if (!order.quantity) {
            errors[`groups.${groupIndex}.orders.${index}.quantity`] = 'Quantity is required';
          }
          group.eventIds.forEach((eventId) => {
            const eventOrderHash = `${eventId}-${order.section}-${order.row}-${order.seat_from}`;
            if (eventOrderHashes.has(eventOrderHash)) {
              errors[`groups.${groupIndex}.orders.${index}.section`] = 'Duplicate section, row, and seat in another listing';
              errors[`groups.${groupIndex}.orders.${index}.row`] = 'Duplicate section, row, and seat in another listing';
              errors[`groups.${groupIndex}.orders.${index}.seat_from`] = 'Duplicate section, row, and seat in another listing';
            } else {
              eventOrderHashes.add(eventOrderHash);
            }
          });
        });
      });
      // TODO: Add validation for seat ranges that they dont overlap.
      return errors;
    },
  });
  const formValues = useMemoDeep(() => form.values, [form.values]);

  useDidUpdate(() => {
    if (form.values.groups.length === 1 && form.values.groups[0].orders[0].section === '') {
      form.setFieldValue(
        'groups.0.eventIds',
        selectedEvents.map((x) => x.id),
      );
    }
  }, [selectedEvents]);

  useDidUpdate(() => {
    const formEventIds = formValues.groups.flatMap((group) => group.eventIds);
    setOrderEvents((event) => event.filter((x) => formEventIds.includes(x.id)).concat(selectedEvents));
  }, [selectedEvents, formValues.groups.length]);

  useDidUpdate(() => {
    formValues.groups.forEach((group, groupIndex) => {
      group.orders.forEach((order, index) => {
        if (order.quantity && order.seat_from && order.barcodes && order.quantity > order.barcodes.filter(Boolean).length - 1) {
          const updatedOrder = { ...order };
          updatedOrder.barcodes = Array.from({ length: order.quantity }, (_, i) => (order.barcodes[i] !== undefined ? order.barcodes[i] : ''));
          form.setFieldValue(`groups.${groupIndex}.orders.${index}`, updatedOrder);
        }
      });
    });
  }, [formValues]);

  const { mutateAsync: bulkPurchase } = usePostDtiListingsAccountId();
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = useCallback(
    async (values: typeof formValues) => {
      setIsSaving(true);
      if (selectedAccountId) {
        const flattenedOrders = values.groups.flatMap((group) =>
          group.eventIds.flatMap((eventId) =>
            group.orders.flatMap(
              (order) =>
                ({
                  // TODO: Fix nullabilty and remove type assertion
                  event_id: Number(eventId),
                  quantity: order.quantity!,
                  cost: order.cost!,
                  price: order.price!,
                  stock: order.stock!,
                  section: order.section,
                  row: order.row,
                  seat_from: order.seat_from!,
                  notes: order.disclosures.concat(order.notes).join(' '),
                  internal_notes: order.internal_notes,
                  purchaser: order.purchaser,
                  account_id: selectedAccountId,
                  account_info: order.account_info,
                  order_number: order.order_number,
                  barcodes: order.barcodes,
                  multiday: order.multiday,
                  odd_even: order.odd_even,
                }) satisfies BarkerCoreModelsDTIBulkPurchaseRequest,
            ),
          ),
        );

        await bulkPurchase({
          accountId: selectedAccountId,
          data: flattenedOrders,
        });
      }

      await delay(5 * 1000); // HACK: This is to give the API time to process the request before closing the modal.
      onClose?.(true);
      setIsSaving(false);
    },
    [bulkPurchase, onClose, selectedAccountId],
  );

  type SearchForm = {
    fromDate: Date | null;
    toDate: Date | null;
    category: string | undefined;
    venue: string | undefined;
    headliner: string | undefined;
  };

  type FiltersForm = {
    query: string | undefined;
    excludeParking: boolean;
    excludeEventsWithoutTickets: boolean;
  };

  const searchForm = useForm<SearchForm>({
    mode: 'uncontrolled',
    initialValues: {
      fromDate: dayjs().toDate(),
      toDate: dayjs().toDate(),
      category: '',
      venue: '',
      headliner: '',
    },
    validate: {
      fromDate: (value) => {
        if (!value) {
          return 'From Date is required';
        }
        return undefined;
      },
      toDate: (value, values) => {
        if (!value) {
          return 'To Date is required';
        }
        if (value && values.fromDate && dayjs(value).isBefore(dayjs(values.fromDate))) {
          return 'To Date must be after From Date';
        }
        return undefined;
      },
    },
  });
  const filtersForm = useForm<FiltersForm>({
    initialValues: {
      excludeParking: true,
      excludeEventsWithoutTickets: false,
      query: '',
    },
  });

  const [submittedSearchValues, setSubmittedSearchValues] = useState<SearchForm | null>(null);

  const { data: _eventSearchResults, isFetching: isCatalogLoading } = useGetDtiEvents(
    {
      from: dayjs(submittedSearchValues?.fromDate).format(DateFormats.DtiDate),
      to: dayjs(submittedSearchValues?.toDate).format(DateFormats.DtiDate),
      headliner: submittedSearchValues?.headliner,
      category: submittedSearchValues?.category,
      venue: submittedSearchValues?.venue,
    },
    {
      query: {
        staleTime: import.meta.env.DEV ? 1000 * 60 * 30 : undefined,
        cacheTime: import.meta.env.DEV ? 1000 * 60 * 30 : undefined,
        enabled: !!submittedSearchValues,
        select(data) {
          return data.data?.events;
        },
      },
    },
  );

  const eventSearchResults = useMemo(
    () =>
      _eventSearchResults?.filter((x) => {
        const { excludeParking, excludeEventsWithoutTickets, query } = filtersForm.values;
        const eventName = x.event_name.toLowerCase();
        const itemCount = x.stats?.item_count || 0;
        const venueName = x.venue_name.toLowerCase();
        const headliner = x.event_headliner.toLowerCase();
        const queryLower = query?.toLowerCase() || '';

        if (excludeParking && eventName.includes('parking')) {
          return false;
        }

        if (excludeEventsWithoutTickets && itemCount === 0) {
          return false;
        }

        if (query && !eventName.includes(queryLower) && !venueName.includes(queryLower) && !headliner.includes(queryLower)) {
          return false;
        }

        return true;
      }) ?? [],
    [_eventSearchResults, filtersForm.values],
  );

  if (isCatalogLoading) {
    // NOTE: This was added as prop finally in the newer version of AG-Grid. Remove when upgraded.
    catalogGridRef.current?.api.showLoadingOverlay();
  }

  useDidUpdate(() => {
    if (!isCatalogLoading) {
      // NOTE: This was added as prop finally in the newer version of AG-Grid. Remove when upgraded.
      catalogGridRef.current?.api.hideOverlay();
    }
  }, [isCatalogLoading]);

  const onSearchSubmit = useCallback((values: SearchForm) => {
    setSubmittedSearchValues(values);
  }, []);

  type CatalogEventColumn = ColDef<BarkerCoreModelsDTIGetEventsResponseItem> & {
    exclude?: boolean;
    allowSorting?: boolean;
    sort?: 'asc' | 'desc' | null;
    sortIndex?: number | null;
  };
  const defaultCatalogDefs: CatalogEventColumn[] = useMemo(
    () =>
      [
        { colId: 'eventId', field: 'eventId', exclude: true, allowSorting: false, hide: true },
        { colId: 'dragCell', headerCheckboxSelection: true, exclude: true, allowSorting: false, width: 50, minWidth: 50, maxWidth: 50, valueGetter: () => null },
        { headerName: 'Name', field: 'event_name', flex: 1, minWidth: 260, sort: undefined },
        {
          headerName: 'Date',
          field: 'event_date_time',
          sortIndex: 0,
          sort: 'asc',
          valueGetter: (params) => (params.data?.event_date_time ? formatDate(dayjs(params.data.event_date_time).toDate(), DateFormats.GridFullWithDOW) : null),
          cellStyle: { fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
        },
        { headerName: 'Venue', field: 'venue_name', minWidth: 200 },
        { headerName: 'Qty', field: 'stats.item_count', minWidth: 70, width: 70 },
        { headerName: 'Headliner', field: 'event_headliner', minWidth: 150 },
      ] as CatalogEventColumn[],
    [],
  );

  type SummaryColumn = ColDef<SummaryGridItem> & {
    exclude?: boolean;
    allowSorting?: boolean;
    sort?: 'asc' | 'desc' | null;
    sortIndex?: number | null;
  };
  const defaultSummaryDefs: SummaryColumn[] = useMemo(
    () =>
      [
        { colId: 'event_id', field: 'event_id', exclude: true, allowSorting: false, hide: true },
        { headerName: 'Name', field: 'name', flex: 1, minWidth: 260, sort: undefined, hide: true },
        {
          headerName: 'Date',
          field: 'event_date_time',
          hide: true,
          sortIndex: 0,
          sort: 'asc',
          valueGetter: (params) => (params.data?.event_date_time ? formatDate(dayjs(params.data.event_date_time).toDate(), DateFormats.GridFull) : null),
          cellStyle: { fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
        },
        { headerName: 'Headliner', field: 'event_headliner', minWidth: 150, hide: true },
        { headerName: 'Venue', field: 'venue_name', minWidth: 200, hide: true },
        { headerName: 'Section', field: 'section', minWidth: 80, width: 160, sort: 'asc', sortIndex: 1 },
        {
          headerName: 'Row',
          field: 'row',
          minWidth: 70,
          width: 70,
          cellStyle: {
            textAlign: 'center',
          },
        },
        {
          headerName: 'Seats',
          colId: 'seats',
          minWidth: 90,
          width: 90,
          cellStyle: {
            textAlign: 'center',
          },
          valueGetter: (params) => {
            if (params.data) {
              return calculateSeatRange(params.data.seat_from, params.data.quantity, params.data.odd_even);
            }
            return null;
          },
        },
        { headerName: 'Qty', field: 'quantity', minWidth: 70, width: 70, sort: 'desc', sortIndex: 2 },
        {
          headerName: 'Cost',
          field: 'cost',
          minWidth: 110,
          width: 110,
          cellStyle: {
            textAlign: 'right',
          },
          valueFormatter: (params) => formatCurrency(params.value),
        },
        {
          headerName: 'Price',
          field: 'price',
          minWidth: 110,
          width: 110,
          cellStyle: {
            textAlign: 'right',
          },
          valueFormatter: (params) => formatCurrency(params.value),
        },
        { headerName: 'stock', field: 'stock', minWidth: 130, width: 130, valueGetter: (params) => stock_options.find((x) => x.value === params.data?.stock?.toString())?.label },
        { headerName: 'Notes', colId: 'notes', minWidth: 120, valueGetter: (params) => params.data?.disclosures.concat(params.data.notes).join(' ') },
        { headerName: 'Internal Notes', field: 'internal_notes', minWidth: 120, hide: false },
        { headerName: 'Order Number', field: 'order_number', minWidth: 120, width: 120, hide: false },
        { headerName: 'Account Info', field: 'account_info', minWidth: 120, width: 120, hide: false },
        { headerName: 'Purchaser', field: 'purchaser', minWidth: 120, width: 180, hide: false },
        { headerName: 'Seating Type', field: 'odd_even', hide: false, minWidth: 120, width: 120, valueGetter: (params) => (params.data?.odd_even ? 'Odd/Even' : 'Consecutive') },
      ] as SummaryColumn[],
    [],
  );

  const [eventGridState, setEventGridState, clearEventGridState] = useLocalStorage<ColumnState[]>({
    key: 'catalog-grid-event-state',
    getInitialValueInEffect: false,
  });

  const catalogColumnDefs: ColDef<BarkerCoreModelsDTIGetEventsResponseItem>[] = useMemo(
    () =>
      defaultCatalogDefs
        .map((col) => {
          // Clears properties before giving it to AG-Grid to avoid warnings.
          const { allowSorting, exclude, ..._col } = col;
          if (_col.colId === 'dragCell') {
            _col.checkboxSelection = true;
          }
          const savedState = { ...eventGridState?.filter((x) => !['dragCell'].includes(x.colId)).find((y) => y.colId === col.colId || y.colId === col.field) };
          return { ..._col } as ColDef<BarkerCoreModelsDTIGetEventsResponseItem>;
        })
        .sort((a, b) => {
          const aIndex = eventGridState?.findIndex((x) => x.colId === a.colId);
          const bIndex = eventGridState?.findIndex((x) => x.colId === b.colId);
          return aIndex - bIndex;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const summaryColumnDefs: ColDef<SummaryGridItem>[] = useMemo(
    () =>
      defaultSummaryDefs
        .map((col) => {
          // Clears properties before giving it to AG-Grid to avoid warnings.
          const { allowSorting, exclude, ..._col } = col;
          if (_col.field === 'event_id') {
            _col.rowGroup = true;
            _col.hide = true;
          }
          const savedState = { ...eventGridState?.filter((x) => !['dragCell'].includes(x.colId)).find((y) => y.colId === col.colId || y.colId === col.field) };
          return { ..._col } as ColDef<SummaryGridItem>;
        })
        .sort((a, b) => {
          const aIndex = eventGridState?.findIndex((x) => x.colId === a.colId);
          const bIndex = eventGridState?.findIndex((x) => x.colId === b.colId);
          return aIndex - bIndex;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onCatalogGridReady = useCallback(
    (event: GridReadyEvent) => {
      if (selectedEvents) {
        event.api.forEachNode((node: IRowNode<BarkerCoreModelsDTIGetEventsResponseItem>) => {
          if (selectedEvents.some((x) => x.id === node.data?.id)) {
            node.setSelected(true);
          }
        });
      }
    },
    [selectedEvents],
  );

  const removeCatalogEvent = useCallback((event: BarkerCoreModelsDTIGetEventsResponseItem) => {
    setSelectedEvents((s) => {
      s.includes(event) ? s.splice(s.indexOf(event), 1) : s.push(event);
      // Update api-grid selections before returning
      catalogGridRef.current?.api.forEachNode((node) => {
        if (s.some((x) => x.id === node.data?.id)) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
      return [...s];
    });
  }, []);

  const addTicketGroup = useCallback(() => {
    const { hasErrors } = form.validate();
    const order = form.values.groups[activeListing.groupIndex]?.orders[activeListing.index];
    const isEmptyRecord = order && !order.section && !order.row && !order.seat_from && !order.quantity;

    if (!hasErrors || isEmptyRecord) {
      if (isEmptyRecord) {
        if (form.values.groups[activeListing.groupIndex].orders.length === 1) {
          form.removeListItem('groups', activeListing.groupIndex);
        } else {
          form.removeListItem(`groups.${activeListing.groupIndex}.orders`, activeListing.index);
        }
      }
      form.insertListItem('groups', {
        orders: [{ ...structuredClone(DEFAULT_ORDER), key: uuid() } satisfies Order],
        eventIds: selectedEvents.map((x) => x.id),
        group: uuid(),
      } satisfies TicketGroup);
      setActiveListing({ groupIndex: form.values.groups.length + (isEmptyRecord && form.values.groups[activeListing.groupIndex].orders.length === 1 ? -1 : 0), index: 0 });
    }
  }, [activeListing.groupIndex, activeListing.index, form, selectedEvents]);

  const addListing = useCallback(
    (groupIndex: number, tail?: boolean) => {
      const { hasErrors } = form.validate();
      const order = form.values.groups[activeListing.groupIndex]?.orders[activeListing.index];
      const isEmptyRecord = order && !order.section && !order.row && !order.seat_from && !order.quantity;
      if (!hasErrors && !isEmptyRecord) {
        form.insertListItem(`groups.${groupIndex}.orders`, { ...structuredClone(DEFAULT_ORDER), key: uuid() } satisfies Order, tail ? undefined : 0);
        setActiveListing({ groupIndex, index: tail ? form.values.groups[groupIndex].orders.length : 0 });
      }
    },
    [activeListing.groupIndex, activeListing.index, form],
  );

  useDidUpdate(() => {
    scrollIntoView({ alignment: 'center' });
  }, [activeListing.groupIndex, activeListing.index]);

  const copyListing = useCallback(
    (groupIndex: number, index: number) => {
      const { hasErrors } = form.validate();
      if (!hasErrors) {
        const listing = form.values.groups[groupIndex].orders[index];
        form.insertListItem(`groups.${groupIndex}.orders`, { ...structuredClone(listing), key: uuid() } satisfies Order, index);
        setActiveListing({ groupIndex, index: index + 1 });
      }
    },
    [form],
  );

  const mergedEventsWithInventory: SummaryGridItem[] = useMemo(() => {
    const distinctEventIds = new Set(...[formValues.groups.flatMap((x) => x.eventIds)]);
    return Array.from(distinctEventIds).flatMap((eventId) => {
      const event = orderEvents.find((x) => x.id === eventId)!;
      return formValues.groups
        .filter((x) => x.eventIds.includes(eventId))
        .flatMap((x) => x.orders)
        .filter((x) => x.section)
        .map((order) => ({ ...order, ...event, event_id: event.id }));
    });
  }, [formValues.groups, orderEvents]);

  const totalCost = useMemo(
    () =>
      formValues.groups.reduce(
        (totalAcc, group) => totalAcc + group.orders.reduce((groupAcc, order) => groupAcc + (order.cost || 0) * (order.quantity || 0) * group.eventIds.length, 0),
        0,
      ),
    [formValues],
  );
  const totalTickets = useMemo(
    () => formValues.groups.reduce((totalAcc, group) => totalAcc + group.orders.reduce((groupAcc, order) => groupAcc + (order.quantity || 0) * group.eventIds.length, 0), 0),
    [formValues],
  );
  const totalListings = useMemo(() => formValues.groups.flatMap((x) => x.orders).length * selectedEvents.length, [formValues, selectedEvents]);

  return {
    form,
    searchForm,
    onSubmit,
    DEFAULT_ORDER,
    eventSearchResults,
    selectedEvents,
    setSelectedEvents,
    catalogGridRef,
    summaryGridRef,
    catalogColumnDefs,
    summaryColumnDefs,
    onCatalogGridReady,
    mergedEventsWithInventory,
    totalCost,
    totalListings,
    setActive,
    active,
    nextStep,
    prevStep,
    totalTickets,
    addTicketGroup,
    addListing,
    copyListing,
    orderEvents,
    selectedAccountId,
    setSelectedAccountId,
    activeListing,
    setActiveListing,
    removeCatalogEvent,
    calculateSeatRange,
    onSearchSubmit,
    isCatalogLoading,
    targetRef,
    scrollableRef,
    filtersForm,
    dtiAccounts,
    isSelectEventsEnabled,
    isSaving,
    isAccountError,
  };
}

export const { Provider: PurchaseStateProvider, useSelector: usePurchaseState } = yasml(PurchaseState);

export const disclosures_options = [
  { value: '18+ Event', label: '18+ Event' },
  { value: '21+ Event', label: '21+ Event' },
  { value: 'ADA Wheelchair Accessible', label: 'ADA Wheelchair Accessible' },
  { value: 'Alcohol-Free Seating', label: 'Alcohol-Free Seating' },
  { value: 'Child Ticket', label: 'Child Ticket' },
  { value: 'Limited View (printed on ticket)', label: 'Limited View (printed on ticket)' },
  {
    value: 'MOBILE ENTRY. Scan your tickets from your phone for this event. Do not print these tickets. MOBILE QR',
    label: 'MOBILE ENTRY. Scan your tickets from your phone for this event. Do not print these tickets. MOBILE QR',
  },
  { value: 'Obstructed View (printed on ticket)', label: 'Obstructed View (printed on ticket)' },
  { value: 'Parking Pass Only', label: 'Parking Pass Only' },
  { value: 'Partial View (printed on ticket)', label: 'Partial View (printed on ticket)' },
  { value: 'Piggyback Seats. Same seat number in back to back rows.', label: 'Piggyback Seats. Same seat number in back to back rows.' },
  { value: 'Restricted View (printed on ticket)', label: 'Restricted View (printed on ticket)' },
  { value: 'Side Stage (printed on ticket)', label: 'Side Stage (printed on ticket)' },
  { value: 'Side View (printed on ticket)', label: 'Side View (printed on ticket)' },
  { value: 'Standing Room Only', label: 'Standing Room Only' },
  {
    value: 'Xfer MOBILE ENTRY. Scan your tickets from your phone for this event. Do not print these tickets',
    label: 'Xfer MOBILE ENTRY. Scan your tickets from your phone for this event. Do not print these tickets',
  },
];

export const location_options = [
  { value: '26', label: '1TICKET' },
  { value: '36', label: 'AUTO HARD' },
  { value: '15', label: 'D2' },
  { value: '38', label: 'DELETE' },
  { value: '27', label: 'DROPSHIP' },
  { value: '32', label: 'EDELIVERY' },
  { value: '22', label: 'FLASH', primary: true },
  { value: '37', label: 'FLASH - SH' },
  { value: '19', label: 'HARD', primary: true },
  { value: '11', label: 'Home Office' },
  { value: '35', label: 'LMS' },
  { value: '28', label: 'LMS TEST' },
  { value: '50', label: 'MD', primary: true },
  { value: '31', label: 'MOBILE', primary: true },
  { value: '39', label: 'MOBILE_XFR', primary: true },
  { value: '47', label: 'MULTI-FLS' },
  { value: '20', label: 'MULTI-HARD' },
  { value: '21', label: 'MULTI-TF' },
  { value: '48', label: 'MULTI-XFR' },
  { value: '33', label: 'NONVENT-HD' },
  { value: '43', label: 'NONVENT-TF' },
  { value: '25', label: 'PAPERLESS' },
  { value: '24', label: 'WALK-IN' },
  { value: '23', label: 'WILL CALL' },
];

export const stock_options = [
  { value: '0', label: 'E-Ticket' },
  { value: '5', label: 'Flash' },
  { value: '1', label: 'Hard' },
  { value: '4', label: 'Mobile' },
  { value: '8', label: 'Mobile Transfer' },
  { value: '2', label: 'Paperless' },
  { value: '3', label: 'Willcall' },
];

export const mapStringToDisclosures = (disclosures: string): { disclosures: string[]; additionalText: string } => {
  let _disclosures = disclosures;
  const foundDisclosures: string[] = [];
  disclosures_options.forEach(({ value }) => {
    if (disclosures.includes(value)) {
      _disclosures = _disclosures.replace(value, '');
      foundDisclosures.push(value);
    }
  });
  return { disclosures: foundDisclosures, additionalText: _disclosures.trim() };
};

export const calculateSeatRange = (seatFrom: number | undefined, quantity: number | undefined, isOddEven: boolean): string => {
  if (seatFrom === undefined) {
    return '';
  }
  if (!quantity || quantity === 1) {
    return seatFrom.toString();
  }
  const endSeat = isOddEven ? seatFrom + (quantity - 1) * 2 : seatFrom + quantity - 1;
  return `${seatFrom}-${endSeat}`;
};
